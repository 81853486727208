import React from "react";
import { createRoot } from 'react-dom/client';

import { CaseManager, APPS, FEATURES } from "@flow/case-manager";

import * as serviceWorker from "./serviceWorker";

import "@stacc/flow-ui-style/dist/reset.css";
import "@stacc/flow-ui-style/dist/global.css";
import "./index.css";

import { queueDefinition } from "./configuration/queues";
import { caseConfig } from "./configuration/case";
import customTranslations from "./translations";

const FlowPortalWithApps = (
  <CaseManager
    configuration={() => {
      return {
        devtoolsEnabled: true,
        apps: [APPS.Inbox, APPS.Search, APPS.Process],
        inboxConfiguration: {
          startableProcesses: ["application"],
          features: [FEATURES.CaseAssignment, FEATURES.CaseNotes]
        },
        searchConfiguration: {
          searchQueryView: "searchView",
          search: {
            sort: [
              { name: "Newest", value: "createdAt", direction: "desc" },
              { name: "Oldest", value: "createdAt", direction: "asc" },
              { name: "Recently changed", value: "updatedAt", direction: "desc" },
            ],
            fields: [
              { name: "Created after", value: "createdAt", type: "date", queryOperator: "gte" },
              { name: "Created before", value: "createdAt", type: "date", queryOperator: "lte" },
              { name: "Business status", value: "data.businessStatus", type: "select", options: [
                { label: "bs-start", value:  "bs-start"},
                { label: "bs-user-task1", value:  "bs-user-task1"},
                { label: "bs-user-task2", value:  "bs-user-task2"},
                { label: "bs-foobar", value:  "bs-foobar"},
                { label: "bs-fuzz", value:  "bs-fuzz"},
              ]},
/*               { name: "FoobarDate after", value: "data.foobarDate", type: "date", queryOperator: "gte" },
              { name: "FoobarDate before", value: "data.foobarDate", type: "date", queryOperator: "lte" } */
            ]
          },
          searchResults: {
            fields: [
              { name: "CreatedAt", value: "createdAt" },
              { name: "Business status", value: "data.businessStatus" }
              //{ name: "FoobarDate", value: "data.foobarDate", mapping: (v) => v },
            ],
            businessStatus: {
              map: flow => {
                return flow.data.businessStatus;
              }
            }
          },
          features: [FEATURES.CaseAssignment, FEATURES.CaseNotes],
        },
        caseNotesConfiguration: {
          sort: "DESC"
        },
        customComponents: {
          queueDefinition,
          case: caseConfig
        },
        language: "en",
        translates: [
          {
            language: "no",
            translates: customTranslations.no
          },
          {
            language: "en",
            translates: customTranslations.en
          }
        ]
      }
    }
  }/>
);


const root = createRoot(document.getElementById('root'));
root.render(FlowPortalWithApps);


serviceWorker.unregister();
