const caseConfig = {
  caseSummary: {
    map: (flow) => {
      const { _meta, status, createdAt } = flow;
      return [
        { label: "Status", title: status },
        { label: "Created", title: createdAt.split("T")[0] },
        { label: "Process version", title: _meta.processVersion }
      ]
    }
  },
  //caseHistoryTasksLimit: 400
};

export { caseConfig };
